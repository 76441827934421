.pulse {
    /*margin-top: 100px;*/
    /*margin-left: 50px;*/
    /*margin: 100px;*/
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #3B888C;
    box-shadow: 0 0 0 rgba(41, 185, 192, 0.6);
    animation: pulse 2s infinite;
}

.pulse2 {
    /*margin-top: 100px;*/
    /*margin-left: 50px;*/
    /*margin: 100px;*/
    font-family: 'Alegreya Sans', serif;
    display: block;
    position: absolute;
    top: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #3B888C;
    color: #fff;
    font-size: 47px;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.2);
    animation: pulse2 2s infinite;
}

.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 25px rgba(41, 185, 192, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(59, 136, 140, 0.6);
        box-shadow: 0 0 0 0 rgba(59, 136, 140, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 25px rgba(41, 185, 192, 0);
        box-shadow: 0 0 0 25px rgba(41, 185, 192, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
        box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}


@-webkit-keyframes pulse2 {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.2);
    }
    70% {
        -webkit-box-shadow: 0 0 0 45px rgba(41, 185, 192, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}

@keyframes pulse2 {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.2);
        box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 55px rgba(41, 185, 192, 0);
        box-shadow: 0 0 0 45px rgba(41, 185, 192, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
        box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}
