.main-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.video-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.video-container-chat {
    width: calc(100% - 400px);
}

.video-local {
    position: fixed;
    z-index: 1;
    width: 12%;
    bottom: 40px;
    left: 40px;
}

.video-local-share {
    bottom: 90px;
}

.video-remote {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 20px 80px;
}

.video-actions {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.video-actions-center {
    text-align: center;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.video-actions-button {
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
}

.chat {
    height: 100%;
    width: 400px;
    background-color: white;
}
