.video {
    display: block;
    border-radius: 16px;
    object-fit: contain;
}

.video-circle {
    border-radius: 50%;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.video-circle:hover {
    outline: 2px solid white;
}
