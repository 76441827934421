@import url(https://fonts.googleapis.com/css?family=Abril+Fatface|Lato);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&display=swap);
.pulse {
    /*margin-top: 100px;*/
    /*margin-left: 50px;*/
    /*margin: 100px;*/
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #3B888C;
    box-shadow: 0 0 0 rgba(41, 185, 192, 0.6);
    -webkit-animation: pulse 2s infinite;
            animation: pulse 2s infinite;
}

.pulse2 {
    /*margin-top: 100px;*/
    /*margin-left: 50px;*/
    /*margin: 100px;*/
    font-family: 'Alegreya Sans', serif;
    display: block;
    position: absolute;
    top: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #3B888C;
    color: #fff;
    font-size: 47px;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.2);
    -webkit-animation: pulse2 2s infinite;
            animation: pulse2 2s infinite;
}

.pulse:hover {
    -webkit-animation: none;
            animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 25px rgba(41, 185, 192, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(59, 136, 140, 0.4);
    }
    70% {
        box-shadow: 0 0 0 25px rgba(41, 185, 192, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}


@-webkit-keyframes pulse2 {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.2);
    }
    70% {
        -webkit-box-shadow: 0 0 0 45px rgba(41, 185, 192, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}

@keyframes pulse2 {
    0% {
        box-shadow: 0 0 0 0 rgba(41, 185, 192, 0.4);
    }
    70% {
        box-shadow: 0 0 0 45px rgba(41, 185, 192, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(41, 185, 192, 0);
    }
}

.participant {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #00000052;
}

.participant-circle {
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
}

.name-tag {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1F3539;
    border-radius: 4px;
    padding: 4px 12px;
}

.collapse {
    cursor: pointer;
    margin-left: 5px;
}

.actions {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1F3539;
    border-radius: 4px;
    padding: 4px 12px;
}

.video {
    display: block;
    border-radius: 16px;
    object-fit: contain;
}

.video-circle {
    border-radius: 50%;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.video-circle:hover {
    outline: 2px solid white;
}

.main-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.video-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.video-container-chat {
    width: calc(100% - 400px);
}

.video-local {
    position: fixed;
    z-index: 1;
    width: 12%;
    bottom: 40px;
    left: 40px;
}

.video-local-share {
    bottom: 90px;
}

.video-remote {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 20px 80px;
}

.video-actions {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.video-actions-center {
    text-align: center;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.video-actions-button {
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
}

.chat {
    height: 100%;
    width: 400px;
    background-color: white;
}

body {
    background: #D3DEEA;
}

.top {
    padding-top: 100px;
    text-align: center;
    margin-top: 30px;

    font-size: 40px;
    color: #3C888C;
    font-family: Alegreya Sans, serif;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
}

.container {
    margin: 50px auto 0;
    position: relative;
    width: 250px;
    height: 250px;
}

.ghost {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: #EDEDED;
    border: 1px solid #BFC0C0;
    border-bottom: none;
    -webkit-animation: float 2s ease-out infinite;
            animation: float 2s ease-out infinite;
}

.ghost-copy {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: #EDEDED;
    border: 1px solid #BFC0C0;
    border-bottom: none;
    -webkit-animation: float 2s ease-out infinite;
            animation: float 2s ease-out infinite;
    z-index: 0;
}

.face {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 20%;
}

.eye, .eye-right {
    position: absolute;
    background: #585959;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 40%;
}

.eye {
    left: 25%;
}

.eye-right {
    right: 25%;
}

.mouth {
    position: absolute;
    top: 50%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: 3px solid;
    border-radius: 50%;
    border-color: transparent #585959 #585959 transparent;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.one, .two, .three, .four {
    position: absolute;
    background: #EDEDED;
    top: 85%;
    width: 25%;
    height: 23%;
    border: 1px solid #BFC0C0;
    z-index: 0;
}

.one {
    border-radius: 0 0 100% 30%;
    left: -1px;
}

.two {
    left: 23%;
    border-radius: 0 0 50% 50%;
}

.three {
    left: 50%;
    border-radius: 0 0 50% 50%;
}

.four {
    left: 74.5%;
    border-radius: 0 0 30% 100%;
}

.shadow {
    position: absolute;
    width: 30%;
    height: 7%;
    background: #BFC0C0;
    left: 35%;
    top: 80%;
    border-radius: 50%;
    -webkit-animation: scale 2s infinite;
            animation: scale 2s infinite;
}

@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-webkit-keyframes float {
    50% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
}
@keyframes float {
    50% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
}
/*.bottom {*/
/*    margin-top: 10px;*/
/*}*/

/*!*text styling*!*/
/*h1 {*/
/*    font-family: "Abril Fatface", serif;*/
/*    color: #EDEDED;*/
/*    text-align: center;*/
/*    font-size: 9em;*/
/*    margin: 0;*/
/*    text-shadow: -1px 0 #BFC0C0, 0 1px #BFC0C0, 1px 0 #BFC0C0, 0 -1px #BFC0C0;*/
/*}*/

/*h3 {*/
/*    font-family: "Lato", sans-serif;*/
/*    font-size: 2em;*/
/*    text-transform: uppercase;*/
/*    text-align: center;*/
/*    color: #BFC0C0;*/
/*    margin-top: -20px;*/
/*    font-weight: 900;*/
/*}*/

/*p {*/
/*    text-align: center;*/
/*    font-family: "Lato", sans-serif;*/
/*    color: #585959;*/
/*    font-size: 0.6em;*/
/*    margin-top: -20px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.search {*/
/*    text-align: center;*/
/*}*/

/*.buttons {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-top: 10px;*/
/*}*/


.rs-sidenav-inverse {
    background-color: #3B888C;
}

.rs-sidenav-inverse .rs-sidenav-item {
    background-color: #3B888C;
}

.rs-sidenav-inverse .rs-sidenav-item:hover {
    background-color: #479296;
}

.rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-active, .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active {
    background-color: #479296;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    border-color: #3B888C;
    background-color: #3B888C;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    color: #3B888C;
    border-color: #3B888C;
}

.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title:after {
    border-color: #3B888C;
}

