@import url("https://fonts.googleapis.com/css?family=Abril+Fatface|Lato");
body {
    background: #D3DEEA;
}

.top {
    padding-top: 100px;
    text-align: center;
    margin-top: 30px;

    font-size: 40px;
    color: #3C888C;
    font-family: Alegreya Sans, serif;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
}

.container {
    margin: 50px auto 0;
    position: relative;
    width: 250px;
    height: 250px;
}

.ghost {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: #EDEDED;
    border: 1px solid #BFC0C0;
    border-bottom: none;
    animation: float 2s ease-out infinite;
}

.ghost-copy {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: #EDEDED;
    border: 1px solid #BFC0C0;
    border-bottom: none;
    animation: float 2s ease-out infinite;
    z-index: 0;
}

.face {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 20%;
}

.eye, .eye-right {
    position: absolute;
    background: #585959;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 40%;
}

.eye {
    left: 25%;
}

.eye-right {
    right: 25%;
}

.mouth {
    position: absolute;
    top: 50%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: 3px solid;
    border-radius: 50%;
    border-color: transparent #585959 #585959 transparent;
    transform: rotate(45deg);
}

.one, .two, .three, .four {
    position: absolute;
    background: #EDEDED;
    top: 85%;
    width: 25%;
    height: 23%;
    border: 1px solid #BFC0C0;
    z-index: 0;
}

.one {
    border-radius: 0 0 100% 30%;
    left: -1px;
}

.two {
    left: 23%;
    border-radius: 0 0 50% 50%;
}

.three {
    left: 50%;
    border-radius: 0 0 50% 50%;
}

.four {
    left: 74.5%;
    border-radius: 0 0 30% 100%;
}

.shadow {
    position: absolute;
    width: 30%;
    height: 7%;
    background: #BFC0C0;
    left: 35%;
    top: 80%;
    border-radius: 50%;
    animation: scale 2s infinite;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes float {
    50% {
        transform: translateY(15px);
    }
}
/*.bottom {*/
/*    margin-top: 10px;*/
/*}*/

/*!*text styling*!*/
/*h1 {*/
/*    font-family: "Abril Fatface", serif;*/
/*    color: #EDEDED;*/
/*    text-align: center;*/
/*    font-size: 9em;*/
/*    margin: 0;*/
/*    text-shadow: -1px 0 #BFC0C0, 0 1px #BFC0C0, 1px 0 #BFC0C0, 0 -1px #BFC0C0;*/
/*}*/

/*h3 {*/
/*    font-family: "Lato", sans-serif;*/
/*    font-size: 2em;*/
/*    text-transform: uppercase;*/
/*    text-align: center;*/
/*    color: #BFC0C0;*/
/*    margin-top: -20px;*/
/*    font-weight: 900;*/
/*}*/

/*p {*/
/*    text-align: center;*/
/*    font-family: "Lato", sans-serif;*/
/*    color: #585959;*/
/*    font-size: 0.6em;*/
/*    margin-top: -20px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.search {*/
/*    text-align: center;*/
/*}*/

/*.buttons {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-top: 10px;*/
/*}*/

