@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&display=swap');

.rs-sidenav-inverse {
    background-color: #3B888C;
}

.rs-sidenav-inverse .rs-sidenav-item {
    background-color: #3B888C;
}

.rs-sidenav-inverse .rs-sidenav-item:hover {
    background-color: #479296;
}

.rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-active, .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active {
    background-color: #479296;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    border-color: #3B888C;
    background-color: #3B888C;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    color: #3B888C;
    border-color: #3B888C;
}

.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title:after {
    border-color: #3B888C;
}
