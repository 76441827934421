.participant {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #00000052;
}

.participant-circle {
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
}

.name-tag {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1F3539;
    border-radius: 4px;
    padding: 4px 12px;
}

.collapse {
    cursor: pointer;
    margin-left: 5px;
}

.actions {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1F3539;
    border-radius: 4px;
    padding: 4px 12px;
}
